import {
  Box,
  CircularProgress,
  Divider,
  Grid,
  MenuItem,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { merchantApi } from "../../../redux/api/apiConfig";
import {
  CUSTOMER_TRANSACTION,
  EXPORT_FEE_REPORT,
  MASTER_BALANCE,
  MASTER_TRANSACTION,
  SUPPLIER,
  SUPPLIER_BUS,
} from "../../../ApiUrl";
import { toast } from "react-toastify";
import { fCurrency } from "../../../utils/formatNumber";
import MasterTransactionReportTable from "./masterTransactionReportsTable";
import usePageTitle from "../../../hooks/usePageTitle";
import {
  formatDate,
  getDateRange,
  searchableSelectFetchOptions,
} from "../../../utils/common";
import {
  ButtonResetFilter,
  ButtonSecondary,
  Iconify,
} from "../../../components";
import SearchableFilterDropdown from "../../../components/SearchableFilterDropdown";
import { DatePicker } from "@mui/x-date-pickers";
import { FilterDropdown } from "../components";
import { reportTimePeriods } from "../common";
import { format } from "date-fns";

const MasterTransactionReport = ({ client }) => {
  usePageTitle("Transaction Report", "View Transaction Report");
  const [transactionData, setTransactionData] = useState({});
  const [customerBalance, setCustomerBalance] = useState(0);
  const [openingBalance, setOpeningBalance] = useState(0);
  const [loading, setLoading] = useState(false);
  const [loadingBalance, setLoadingBalance] = useState(false);
  const [filters, setFilters] = useState({
    date_range_after: getDateRange("month")?.fromDate,
    date_range_before: getDateRange("month")?.toDate,
    period: "month",
    customer_id: null,
  });
  const [paginationFilter, setPaginationFilter] = useState({
    currentPage: 0,
    pageSize: 7,
  });
  const theme = useTheme();
  async function getTransactionData(filters, otherFilters) {
    if (otherFilters?.customer_id) {
      let params = {
        offset: filters?.currentPage * filters?.pageSize,
        limit: filters?.pageSize,
        date_range_after: formatDate(
          otherFilters?.date_range_after,
          "yyyy-MM-dd"
        ),
        date_range_before: formatDate(
          otherFilters?.date_range_before,
          "yyyy-MM-dd"
        ),
        customer_id:
          otherFilters?.customer_id?.value === "Master Account" ||
          otherFilters?.customer_id?.value === "Technical Sub Account"
            ? null
            : otherFilters?.customer_id?.value,
        account_type:
          otherFilters?.customer_id?.value === "Master Account"
            ? "master"
            : otherFilters?.customer_id?.value === "Technical Sub Account"
            ? "technical"
            : null,
      };
      setLoading(true);
      setLoadingBalance(true);
      merchantApi
        .get(`${MASTER_TRANSACTION}`, {
          params,
        })
        .then((response) => {
          if (response?.data) {
            setLoading(false);
            setLoadingBalance(false);
            // console.log(response?.data);
            setTransactionData(response?.data);
            setCustomerBalance(response?.data?.total_balance);
            setOpeningBalance(response?.data?.opening_balance);
          }
        })
        .catch((error) => {
          setLoading(false);
          setLoadingBalance(false);
          toast.error(
            error?.response?.data?.message || "Error getting transaction list"
          );
        });
    } else {
      setTransactionData({});
      setCustomerBalance(0);
      setOpeningBalance(0);
    }
  }

  useEffect(() => {
    getTransactionData(paginationFilter, filters);
  }, [paginationFilter, filters]);

  const handleClearFilters = () => {
    setFilters({
      date_range_after: getDateRange("month")?.fromDate,
      date_range_before: getDateRange("month")?.toDate,
      period: "month",
      customer_id: null,
    });
  };

  const handleExportExcel = async (filters) => {
    try {
      const response = await merchantApi.get(`${MASTER_TRANSACTION}export/`, {
        responseType: "blob",
        params: {
          date_range_after: formatDate(filters?.date_range_after, "yyyy-MM-dd"),
          date_range_before: formatDate(
            filters?.date_range_before,
            "yyyy-MM-dd"
          ),
          customer_id:
            filters?.customer_id?.value === "Master Account" ||
            filters?.customer_id?.value === "Technical Sub Account"
              ? null
              : filters?.customer_id?.value,
          account_type:
            filters?.customer_id?.value === "Master Account"
              ? "master"
              : filters?.customer_id?.value === "Technical Sub Account"
              ? "technical"
              : null,
        },
      });
      console.log("blob response", response);
      // Create a temporary URL for the blob
      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const blobURL = URL.createObjectURL(blob);

      // Create a link element
      const link = document.createElement("a");
      link.href = blobURL;

      // Set the downloaded file name
      link.download = "transaction-report.xlsx";

      // Append to the body, trigger click, and remove the link
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      // Revoke the object URL to free up memory
      URL.revokeObjectURL(blobURL);
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <Box sx={{ padding: "0 24px 24px" }}>
      <Box sx={{ mt: 2 }}>
        <Grid container sx={{ my: 3 }} spacing={1}>
          <Grid item md={4}>
            <Box
              sx={{
                p: 3,
                borderRadius: "7px",
                backgroundColor: theme.palette.common.accentColor,
              }}
              direction={"column"}
              gap={1}
            >
              <Typography
                variant="h6"
                sx={{
                  color: "#fff",
                  fontWeight: "600",
                  fontSize: "25px",
                  mb: 1,
                }}
              >
                Wallet Balance
              </Typography>
              <Divider
                orientation="horizontal"
                flexItem
                sx={{ borderStyle: "solid", borderColor: "#fff" }}
              />
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "repeat(2, 1fr)",
                  gap: 2,
                  mt: 2,
                }}
              >
                <Typography
                  variant="p"
                  sx={{ color: "#fff", fontSize: "45px", fontWeight: 600 }}
                >
                  {loadingBalance ? (
                    <CircularProgress size={25} color="inherit" />
                  ) : (
                    fCurrency(customerBalance)
                  )}
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item md={4}>
            <Box
              sx={{
                p: 3,
                borderRadius: "7px",
                backgroundColor: theme.palette.common.accentColor,
              }}
              direction={"column"}
              gap={1}
            >
              <Typography
                variant="h6"
                sx={{
                  color: "#fff",
                  fontWeight: "600",
                  fontSize: "25px",
                  mb: 1,
                }}
              >
                Opening Balance{" "}
                {filters?.customer_id ? `(${filters?.customer_id?.label})` : ""}
              </Typography>
              <Divider
                orientation="horizontal"
                flexItem
                sx={{ borderStyle: "solid", borderColor: "#fff" }}
              />
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "repeat(2, 1fr)",
                  gap: 2,
                  mt: 2,
                }}
              >
                <Typography
                  variant="p"
                  sx={{ color: "#fff", fontSize: "45px", fontWeight: 600 }}
                >
                  {loadingBalance ? (
                    <CircularProgress size={25} color="inherit" />
                  ) : (
                    fCurrency(openingBalance)
                  )}
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Stack
          sx={{
            overflowX: { xs: "auto" },
            overflowY: { xs: "visible", md: "unset" },
            padding: "5px 0",
          }}
          direction={"row"}
          justifyContent={"space-between"}
          gap={1}
          mt={2}
          width={"100%"}
        >
          <Stack
            sx={{ flexDirection: "row", gap: 1 }}
            divider={
              <Divider
                orientation="vertical"
                flexItem
                sx={{ borderStyle: "dashed" }}
              />
            }
          >
            <Stack sx={{ minWidth: "150px", flex: 1 }}>
              <FilterDropdown
                name="period"
                type="text"
                // label="Time Period"
                options={reportTimePeriods}
                onChange={(e) => {
                  const { fromDate, toDate } = getDateRange(e.target.value);
                  setFilters((prev) => ({
                    ...prev,
                    period: e.target.value,
                    date_range_after: fromDate,
                    date_range_before: toDate,
                  }));
                }}
                value={filters?.period}
                size={"small"}
                render={(period, i) => (
                  <MenuItem key={period.key} value={period.key}>
                    {period.name}
                  </MenuItem>
                )}
                sx={{
                  "& .MuiInputBase-root": {
                    height: "56px", // Set desired height for the input
                    fontSize: "0.875rem", // Adjust font size if necessary
                  },
                  "& .MuiOutlinedInput-input": {
                    padding: "8px", // Adjust padding for content alignment
                  },
                  "& .MuiInputLabel-root": {
                    top: "50%", // Adjust label positioning if needed
                    transform: "translateY(-50%)", // Adjust label positioning if needed
                    left: "10px",
                  },
                  "& .MuiInputLabel-root.Mui-focused": {
                    top: "0px", // Adjust label positioning if needed
                  },
                }}
              />
            </Stack>
            <Stack sx={{ flexDirection: "row", gap: 1 }}>
              <Stack sx={{ width: "200px", flex: 1 }}>
                <DatePicker
                  value={filters?.date_range_after}
                  label="From Date"
                  disableFuture
                  format={"dd/MM/yyyy"}
                  onChange={(newValue) => {
                    setFilters((prev) => ({
                      ...prev,
                      date_range_after: new Date(
                        format(newValue, "yyyy/MM/dd")
                      ),
                    }));
                  }}
                />
              </Stack>

              <Stack sx={{ width: "176px", flex: 1 }}>
                <DatePicker
                  value={filters?.date_range_before}
                  label="To Date"
                  disableFuture
                  format={"dd/MM/yyyy"}
                  onChange={(newValue) => {
                    setFilters((prev) => ({
                      ...prev,
                      date_range_before: new Date(
                        format(newValue, "yyyy/MM/dd")
                      ),
                    }));
                  }}
                />
              </Stack>
            </Stack>
            <Stack sx={{ minWidth: "300px", flex: 1 }}>
              <SearchableFilterDropdown
                label="Select/Search Customer"
                fetchOptions={(query) =>
                  searchableSelectFetchOptions(
                    SUPPLIER_BUS,
                    query,
                    null,
                    (val) => {
                      const defaultData = [
                        {
                          label: "Master Account",
                          value: "Master Account",
                        },
                        {
                          label: "Technical Sub Account",
                          value: "Technical Sub Account",
                        },
                      ];

                      const dynamicData = val?.data?.results
                        ?.filter((item) => item?.sub_account)
                        .map((item) => ({
                          label: item?.business_name, // Adjust based on your API response
                          value: item?.id,
                        }));
                      return [...dynamicData, ...defaultData];
                    }
                  )
                }
                value={filters?.customer_id}
                setSelectedCustomers={(val) =>
                  setFilters((prev) => {
                    return {
                      ...prev,
                      customer_id: val,
                    };
                  })
                }
              />
            </Stack>
          </Stack>
          <Stack sx={{ flexDirection: "row", gap: 1 }}>
            <ButtonResetFilter onClick={handleClearFilters} />
            <ButtonSecondary onClick={() => handleExportExcel(filters)}>
              <Iconify
                icon={"vscode-icons:file-type-excel"}
                sx={{ width: "20px" }}
              />{" "}
              Export
            </ButtonSecondary>
          </Stack>
        </Stack>
        <MasterTransactionReportTable
          translist={transactionData}
          filters={paginationFilter}
          otherFilters={filters}
          setFilters={setPaginationFilter}
          fetchTranslistLoading={loading}
          client={client}
        />
      </Box>
    </Box>
  );
};

export default MasterTransactionReport;
