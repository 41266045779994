import React, { useEffect, useState } from "react";
import BarChartLoader from "../../components/charts/BarChartLoader";
import HeroBarChartLoader from "../../components/charts/HeroBarChartLoader";
import AreaChartComp from "../../components/charts/AreaChartComp";
import { ResponsiveContainer, XAxis, Tooltip, BarChart, Bar } from "recharts";
import { format } from "date-fns";
import { Loader } from "rsuite";
import { Routes } from "../../routes";
import * as API from "../../ApiUrl";
import { merchantApi } from "../../redux/api/apiConfig";
import { useHistory } from "react-router-dom";
import { Button } from "rsuite";
import Dropdown from "react-bootstrap/Dropdown";
import dropDown from "../../assets/img/icons/dropDown.svg";
import { useSelector } from "react-redux";
import {
  formatCurrency,
  formatDate,
  getDateRange,
  handleApiErrors,
} from "../../utils/common";
import { toast } from "react-toastify";
import DoughnutChartComp from "../../components/charts/DoughnutChartComp";
import reload from "../../assets/img/icons/sync.svg";
import usePageTitle from "../../hooks/usePageTitle";
import { reportTimePeriods, timePeriodOptions } from "../reports/common";
import { statusColors } from "../../utils/common";
import { alpha } from "@mui/material/styles";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  IconButton,
  Stack,
  Tooltip as MuiTooltip,
  Typography,
  MenuItem,
} from "@mui/material";
import { FilterDropdown } from "../reports/components";
import { StyledDatePicker } from "../../components/datePicker";
import { ButtonPrimary } from "../../components";
import { fCurrency } from "../../utils/formatNumber";

const doughnutChartColors = [
  statusColors.colors["application submitted"],
  statusColors.colors.complete,
  statusColors.colors.rejected,
  statusColors.colors.pending,
];

export default (props) => {
  const [showIncoming, setShowIncoming] = useState(true);
  const [showOutgoing, setShowOutgoing] = useState(true);
  const [showInternal, setShowInternal] = useState(true);
  const [showFeeIncoming, setShowFeeIncoming] = useState(true);
  const [showFeeOutgoing, setShowFeeOutgoing] = useState(true);
  const [showFeeInternal, setShowFeeInternal] = useState(true);
  const [showFeeMonthly, setShowFeeMonthly] = useState(true);
  const [showFees, setShowFees] = useState(true);
  const [chartData, setChartData] = useState([]);
  const [timePeriod, setTimePeriod] = useState("weekly");
  const [timePeriodTitle, setTimePeriodTitle] = useState("Weekly");
  const [reportTimePeriod, setReportTimePeriod] = useState({
    from: getDateRange(reportTimePeriods[1]?.key)?.fromDate,
    to: getDateRange(reportTimePeriods[1]?.key)?.toDate,
    period: reportTimePeriods[1],
  });

  const [avgRemittanceData, setAvgRemittanceData] = useState({ data: [] });

  const [currencyFlow, setCurrencyFlow] = useState({
    totals: { inflow: 0, outflow: 0 },
  });
  const [customerReport, setCustomerReport] = useState({});

  const [fetchChartDataLoading, setFetchChartDataLoading] = useState(false);
  const [reportLoading, setReportLoading] = useState({
    fees: false,
    avgIncomingTransfer: false,
    customer: false,
    contract: false,
    customer_base: false,
  });
  const [refreshReports, setRefreshReports] = useState(0);
  const [refreshAnalytics, setRefreshAnalytics] = useState(0);
  const [payoutLoading, setPayoutLoading] = useState(false);

  // New State
  const [totalOthersData, setTotalOthersData] = useState(null);
  const [avgIncomingTransferData, setAvgIncomingTransferData] = useState({
    data: [],
  });
  const [contractReportData, setContractReportData] = useState({
    data: [],
    total: 0,
  });
  const [customerBaseReport, setCustomerBaseReport] = useState({
    customerBaseData: [],
    total: {
      customer: 0,
      beneficiary: 0,
      total: 0,
    },
  });
  const [feesReportData, setFeesReportData] = useState({
    feesData: [],
  });
  const history = useHistory();

  usePageTitle("", "");

  const {
    profileReducer: {
      user: {
        is_mediator: isMediator,
        mto: { currency: userCurrency } = {},
      } = {},
    } = {},
  } = useSelector((state) => state);
  const {
    permission,
    user: {
      roles: { key },
    },
  } = useSelector((state) => state.profileReducer);

  const timePeriodChangeHandler = (period) => {
    const periodData = timePeriodOptions.find(
      (option) => option.value === period
    );

    setTimePeriod(period);
    setTimePeriodTitle(periodData?.name);
  };

  function toggleAnalyticsChart(fee) {
    switch (fee) {
      case "outgoing":
        setShowOutgoing(true);
        setShowFees(false);
        setShowInternal(false);
        setShowIncoming(false);
        break;
      case "incoming":
        setShowIncoming(true);
        setShowOutgoing(false);
        setShowInternal(false);
        setShowFees(false);
        break;
      case "internal":
        setShowInternal(true);
        setShowOutgoing(false);
        setShowFees(false);
        setShowIncoming(false);
        break;
      case "fees":
        setShowFees(true);
        setShowIncoming(false);
        setShowInternal(false);
        setShowOutgoing(false);
        break;
      default:
        setShowFees(true);
        setShowIncoming(true);
        setShowInternal(true);
        setShowOutgoing(true);
    }
  }

  function toggleFeesChart(fee) {
    switch (fee) {
      case "outgoing":
        setShowFeeOutgoing(true);
        setShowFeeIncoming(false);
        setShowFeeMonthly(false);
        setShowFeeInternal(false);
        break;
      case "incoming":
        setShowFeeOutgoing(false);
        setShowFeeIncoming(true);
        setShowFeeMonthly(false);
        setShowFeeInternal(false);
        break;
      case "internal":
        setShowFeeOutgoing(false);
        setShowFeeIncoming(false);
        setShowFeeMonthly(false);
        setShowFeeInternal(true);
        break;
      case "monthly":
        setShowFeeOutgoing(false);
        setShowFeeIncoming(false);
        setShowFeeMonthly(true);
        setShowFeeInternal(false);
        break;
      default:
        setShowFeeOutgoing(true);
        setShowFeeIncoming(true);
        setShowFeeMonthly(true);
        setShowFeeInternal(true);
    }
  }

  const payNowClickHandler = () => {
    history.push(Routes.AddOutwardRemittance.path);
  };

  async function getTotalOthersData() {
    setPayoutLoading(true);
    try {
      const response = await merchantApi.get(API.TOTAL_OTHERS_DATA);
      setTotalOthersData(response?.data?.data);
      setPayoutLoading(false);
    } catch (error) {
      setPayoutLoading(false);
      // Handle errors
      const errorMessages = handleApiErrors(error);
      // Display each error in a separate snackbar
      errorMessages.forEach(({ field, message }) => {
        const formattedMessage = field ? `${field}: ${message}` : message; // Include field name if available
        toast.error(formattedMessage);
      });
    }
  }

  async function getAllTransfersData(period) {
    setFetchChartDataLoading(true);
    const params = {
      period: period,
    };
    try {
      const response = await merchantApi.get(API.TRANSFERS_DATA, {
        params,
      });

      let total = {
        incoming: response?.data?.data?.total_incoming || 0,
        internal: response?.data?.data?.total_internal || 0,
        outgoing: response?.data?.data?.total_outgoing || 0,
      };
      let data = [{ Incoming: 0, Internal: 0, Outgoing: 0, Fees: 0, name: "" }];

      if (response?.data?.data?.history?.length > 0) {
        data = response?.data?.data?.history?.map((item) => ({
          Incoming: item?.total_incoming,
          Internal: item?.total_internal,
          Outgoing: item?.total_outgoing,
          Fees: item?.total_fee,
          name: item?.group_date,
        }));
      }
      setChartData({ data, total });
      setFetchChartDataLoading(false);
    } catch (error) {
      setFetchChartDataLoading(false);
      // Handle errors
      const errorMessages = handleApiErrors(error);
      // Display each error in a separate snackbar
      errorMessages.forEach(({ field, message }) => {
        const formattedMessage = field ? `${field}: ${message}` : message; // Include field name if available
        toast.error(formattedMessage);
      });
    }
  }

  async function getContractReportData(period) {
    try {
      setReportLoading((prevState) => ({ ...prevState, contract: true }));
      const params = {
        period: period?.period?.key,
        date_range_after: formatDate(period?.from, "yyyy-MM-dd"),
        date_range_before: formatDate(period?.to, "yyyy-MM-dd"),
      };
      const response = await merchantApi.get(API.CONTRACT_DATA, {
        params,
      });
      const data = response?.data?.data?.history?.map((item) => {
        return {
          name: item?.period,
          Amount: item?.amount,
          Number_of_Contract: item?.no_of_contracts,
        };
      });

      setContractReportData({
        data,
        total: {
          contract: response?.data?.data?.total_contract,
          amount: response?.data?.data?.total_amount,
        },
      });

      setReportLoading((prevState) => ({
        ...prevState,
        contract: false,
      }));
    } catch (error) {
      setReportLoading((prevState) => ({ ...prevState, contract: false }));
      // Handle errors
      const errorMessages = handleApiErrors(error);
      // Display each error in a separate snackbar
      errorMessages.forEach(({ field, message }) => {
        const formattedMessage = field ? `${field}: ${message}` : message; // Include field name if available
        toast.error(formattedMessage);
      });
    }
  }

  async function getAvgIncomingTransferData(period) {
    try {
      setReportLoading((prevState) => ({
        ...prevState,
        avgIncomingTransfer: true,
      }));
      const params = {
        period: period?.period?.key,
        date_range_after: formatDate(period?.from, "yyyy-MM-dd"),
        date_range_before: formatDate(period?.to, "yyyy-MM-dd"),
      };

      const response = await merchantApi.get(API.AVG_INCOMING_TRANSFER_DATA, {
        params,
      });

      let data = [{ Number_of_Transfer: 0, Average: 0, name: "" }];

      if (response?.data?.data?.history?.length > 0) {
        data = response?.data?.data?.history?.map((item) => {
          return {
            Number_of_Transfer: item.no_of_transfers,
            Average: item.average_amount,
            // Fees: item.fees,
            name: item.period,
          };
        });
      }
      setAvgIncomingTransferData({ data });
      setReportLoading((prevState) => ({
        ...prevState,
        avgIncomingTransfer: false,
      }));
    } catch (error) {
      console.log(error);
      setReportLoading((prevState) => ({
        ...prevState,
        avgIncomingTransfer: false,
      }));
      // Handle errors
      const errorMessages = handleApiErrors(error);
      // Display each error in a separate snackbar
      errorMessages.forEach(({ field, message }) => {
        const formattedMessage = field ? `${field}: ${message}` : message; // Include field name if available
        toast.error(formattedMessage);
      });
    }
  }

  async function getCustomerBaseData(period) {
    try {
      setReportLoading((prevState) => ({ ...prevState, customer_base: true }));
      const params = {
        // period: period?.period?.key,
        date_range_after: formatDate(period?.from, "yyyy-MM-dd"),
        date_range_before: formatDate(period?.to, "yyyy-MM-dd"),
      };
      const response = await merchantApi.get(API.CUSTOMER_BASE_DATA, {
        params,
      });
      console.log("total response", response);

      let data = [];
      if (response?.data?.history?.length > 0) {
        data = response?.data?.history?.map((item) => {
          return {
            Customer: item?.supplier,
            Beneficiary: item?.receiver,
            Total: item?.total,
            name: item?.period,
          };
        });
      }

      setCustomerBaseReport({
        customerBaseData: data,
        total: {
          customer: response?.data?.supplier,
          beneficiary: response?.data?.receiver,
          total: response?.data?.total,
        },
      });

      setReportLoading((prevState) => ({ ...prevState, customer_base: false }));
    } catch (error) {
      setReportLoading((prevState) => ({ ...prevState, customer_base: false }));
      // Handle errors
      const errorMessages = handleApiErrors(error);
      // Display each error in a separate snackbar
      errorMessages.forEach(({ field, message }) => {
        const formattedMessage = field ? `${field}: ${message}` : message; // Include field name if available
        toast.error(formattedMessage);
      });
    }
  }

  async function getFeesData(period) {
    try {
      setReportLoading((prevState) => ({ ...prevState, fees: true }));
      const params = {
        period: period?.period?.key,
        date_range_after: formatDate(period?.from, "yyyy-MM-dd"),
        date_range_before: formatDate(period?.to, "yyyy-MM-dd"),
      };
      const response = await merchantApi.get(API.FEES_REPORT_DATA, {
        params,
      });

      let data = [];
      if (response?.data?.history?.length > 0) {
        data = response?.data?.history?.map((item) => {
          // return {
          //   Incoming: 20,
          //   Internal: 10,
          //   Outgoing: 30,
          //   Monthly: 40,
          //   name: item.period,
          // };
          return {
            Incoming: item.incoming_fee,
            Internal: item.incoming_fee,
            Outgoing: item.outgoing_fee,
            Monthly: item.monthly_fee,
            name: item.period,
          };
        });
      }

      setFeesReportData({
        feesData: data,
      });

      setReportLoading((prevState) => ({ ...prevState, fees: false }));
    } catch (error) {
      setReportLoading((prevState) => ({ ...prevState, fees: false }));
      // Handle errors
      const errorMessages = handleApiErrors(error);
      // Display each error in a separate snackbar
      errorMessages.forEach(({ field, message }) => {
        const formattedMessage = field ? `${field}: ${message}` : message; // Include field name if available
        toast.error(formattedMessage);
      });
    }
  }

  useEffect(() => {
    getAllTransfersData(timePeriod);
  }, [timePeriod, refreshAnalytics]);

  useEffect(() => {
    getTotalOthersData();
  }, []);

  useEffect(() => {
    getContractReportData(reportTimePeriod);
    getAvgIncomingTransferData(reportTimePeriod);
    getCustomerBaseData(reportTimePeriod);
    getFeesData(reportTimePeriod);
  }, [reportTimePeriod, refreshReports]);

  // console.log("total response", reportTimePeriod);

  return (
    <>
      <div className="page-wrapper stb-page-wrapper stb-ph-8 bg-white">
        <div className="tabing stb-h-100 p-xl-4 p-3">
          <>
            <div className="reports bg-white radi">
              <div className="d-flex align-items-center pb-4 space-between">
                <div className="col-lg-3">
                  <h3 className="m-0 page-title">
                    Analytics
                    <span
                      className="sync"
                      onClick={() => {
                        setRefreshAnalytics((prevState) => prevState + 1);
                      }}
                    >
                      {" "}
                      <img
                        className={`${fetchChartDataLoading ? "reload" : null}`}
                        src={reload}
                        alt="reload"
                      />
                    </span>
                  </h3>
                  <p>View your stats</p>
                </div>
              </div>
              <div className="dash-hero">
                <div className="hero-graph" style={{ paddingBottom: 0 }}>
                  <div className="hero-filters">
                    <div>
                      <p className="report-title">Transfer</p>
                      <Stack
                        sx={{ flexDirection: "row", alignItems: "center" }}
                      >
                        <p>Incoming</p>
                        <MuiTooltip
                          title="Total Incoming transfers"
                          placement="top"
                        >
                          <IconButton>
                            <InfoOutlinedIcon fontSize="small" />
                          </IconButton>
                        </MuiTooltip>
                      </Stack>
                      <h3 className="static-title">
                        {fCurrency(chartData?.total?.incoming)}
                        {/* {currency.symbol} {dataPointName.currentRevenue} */}
                      </h3>
                      {/* <p>
                          {currentTransTime === '' ? '--' : currentTransTime}
                        </p> */}
                    </div>
                    <div>
                      <p className="report-title">Transfer</p>{" "}
                      <Stack
                        sx={{ flexDirection: "row", alignItems: "center" }}
                      >
                        <p>Internal</p>
                        <MuiTooltip
                          title="Total Internal transfers"
                          placement="top"
                        >
                          <IconButton>
                            <InfoOutlinedIcon fontSize="small" />
                          </IconButton>
                        </MuiTooltip>
                      </Stack>
                      <h3 className="static-title">
                        {fCurrency(chartData?.total?.internal)}
                        {/* {currency.symbol} {dataPointName.previousRevenue} */}
                      </h3>
                      {/* <p>{prevTransTime === '' ? '--' : prevTransTime}</p> */}
                    </div>
                    <div>
                      <p className="report-title">Transfer</p>
                      <Stack
                        sx={{ flexDirection: "row", alignItems: "center" }}
                      >
                        <p>Outgoing</p>
                        <MuiTooltip
                          title="Total Outgoing transfers"
                          placement="top"
                        >
                          <IconButton>
                            <InfoOutlinedIcon fontSize="small" />
                          </IconButton>
                        </MuiTooltip>
                      </Stack>
                      <h3 className="static-title">
                        {fCurrency(chartData?.total?.outgoing)}
                        {/* {currency.symbol} {dataPointName.currentRevenue} */}
                      </h3>
                      {/* <p>
                          {currentTransTime === '' ? '--' : currentTransTime}
                        </p> */}
                    </div>
                    <div>
                      <p className="report-title">Earned</p>
                      <Stack
                        sx={{ flexDirection: "row", alignItems: "center" }}
                      >
                        <p>Fees</p>
                        <MuiTooltip title="Total Fees" placement="top">
                          <IconButton>
                            <InfoOutlinedIcon fontSize="small" />
                          </IconButton>
                        </MuiTooltip>
                      </Stack>
                      <h3 className="static-title">
                        {fCurrency(chartData?.total?.fees)}
                        {/* {currency.symbol} {dataPointName.currentRevenue} */}
                      </h3>
                      {/* <p>
                          {currentTransTime === '' ? '--' : currentTransTime}
                        </p> */}
                    </div>
                    <div>
                      <Dropdown align="end" className="w-100 home-dropdown">
                        <Dropdown.Toggle id="dropdown-basic">
                          {timePeriodTitle || "Select Period"}
                          <img
                            src={dropDown}
                            style={{ marginLeft: "4px" }}
                            alt="drop-down"
                          />
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="super-colors">
                          {timePeriodOptions.map((period, i) => (
                            <Dropdown.Item
                              // as="button"
                              value={period.value}
                              onClick={() => {
                                timePeriodChangeHandler(period.value);
                              }}
                              key={i + "res"}
                            >
                              {period.name}
                            </Dropdown.Item>
                          ))}
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                    <div className="hero-stat-type">
                      <div
                        className={`stat ${showIncoming ? "" : "opacity-5"}`}
                        onClick={() => toggleAnalyticsChart("incoming")}
                      >
                        <span className="stat-selector graph-main-color"></span>
                        <span>Incoming</span>
                      </div>
                      <div
                        className={`stat ${showOutgoing ? "" : "opacity-5"}`}
                        onClick={() => toggleAnalyticsChart("outgoing")}
                      >
                        <span className="stat-selector graph-main-color-sec"></span>
                        <span>Outgoing</span>
                      </div>
                      <div
                        className={`stat ${showInternal ? "" : "opacity-5"}`}
                        onClick={() => toggleAnalyticsChart("internal")}
                      >
                        <span className="stat-selector graph-main-color-ter"></span>
                        <span>Internal</span>
                      </div>
                      <div
                        className={`stat ${showFees ? "" : "opacity-5"}`}
                        onClick={() => toggleAnalyticsChart("fees")}
                      >
                        <span className="stat-selector graph-main-color-light"></span>
                        <span>Fees Earned</span>
                      </div>
                      <div
                        className="stat"
                        onClick={() => toggleAnalyticsChart("reset")}
                      >
                        <span className="stat-selector"></span>
                        <span>Reset</span>
                      </div>
                    </div>
                  </div>
                  <div className="hero-chart">
                    {fetchChartDataLoading ? (
                      <HeroBarChartLoader />
                    ) : (
                      <ResponsiveContainer height="100%" width="100%">
                        <BarChart
                          width={400}
                          height={300}
                          data={chartData.data}
                          margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 0,
                          }}
                          barCategoryGap={20}
                        >
                          <Tooltip />
                          <XAxis
                            scale="point"
                            dataKey="name"
                            tick={{ fontSize: 12, angle: 0 }}
                            // interval={0}
                            interval="preserveStartEnd"
                          />
                          {showIncoming && (
                            <Bar
                              barSize={10}
                              dataKey="Incoming"
                              fill="#1a1f64"
                              radius={[10, 10, 0, 0]}
                            />
                          )}
                          {showInternal && (
                            <Bar
                              barSize={10}
                              dataKey="Internal"
                              fill="#ff9500"
                              radius={[10, 10, 0, 0]}
                            />
                          )}
                          {showOutgoing && (
                            <Bar
                              barSize={10}
                              dataKey="Outgoing"
                              fill="#34c759"
                              radius={[10, 10, 0, 0]}
                            />
                          )}
                          {showFees && (
                            <Bar
                              barSize={10}
                              dataKey="Fees"
                              fill="#5ac8fa"
                              radius={[10, 10, 0, 0]}
                            />
                          )}
                        </BarChart>
                      </ResponsiveContainer>
                    )}
                  </div>
                </div>
                <div className="hero-numbers">
                  <div className="hero-balance">
                    <div style={{ position: "relative" }}>
                      <p>
                        Contracts
                        <span
                          className="sync"
                          onClick={() => {
                            localStorage.setItem(
                              "refreshTimestamp",
                              new Date().getTime()
                            );
                            getTotalOthersData();
                          }}
                        >
                          {" "}
                          <img
                            className={`${payoutLoading ? "reload" : null}`}
                            src={reload}
                            alt="reload"
                          />
                        </span>{" "}
                      </p>
                      {payoutLoading ? (
                        <div style={{ position: "absolute", top: "50%" }}>
                          <Loader content="loading..." />
                        </div>
                      ) : (
                        <h3 className="page-title">
                          {fCurrency(totalOthersData?.contract?.total)}
                        </h3>
                      )}
                    </div>
                    <Button
                      className="btn white-btn action-button basis-30"
                      onClick={() => {
                        history.push(Routes.ProgrammedLiens.path);
                      }}
                      appearance="subtle"
                    >
                      View
                    </Button>
                  </div>

                  <div className="hero-payout">
                    <div style={{ position: "relative" }}>
                      <p>
                        Unmapped Transfers{" "}
                        <span
                          className="sync"
                          onClick={() => {
                            localStorage.setItem(
                              "refreshTimestamp",
                              new Date().getTime()
                            );
                            getTotalOthersData();
                          }}
                        >
                          {" "}
                          <img
                            className={`${payoutLoading ? "reload" : null}`}
                            src={reload}
                            alt="reload"
                          />
                        </span>{" "}
                      </p>
                      {payoutLoading ? (
                        <div style={{ position: "absolute", top: "50%" }}>
                          <Loader content="loading..." />
                        </div>
                      ) : (
                        <h3 className="page-title">
                          {fCurrency(
                            totalOthersData?.unmapped_transafers?.total
                          )}
                        </h3>
                      )}
                    </div>
                    <Button
                      className="btn white-btn action-button basis-30"
                      onClick={() => {
                        history.push(Routes.IncomingTransfer.path);
                      }}
                      appearance="subtle"
                    >
                      View
                    </Button>
                  </div>
                </div>
              </div>
            </div>

            <section>
              <div className="d-flex align-items-start flex-column mt-4 gap-2">
                <div className="col-lg-4" style={{ margin: "30px 0" }}>
                  <h3 className="m-0 page-title">
                    Reports Overview
                    <span
                      className="sync"
                      onClick={() => {
                        setRefreshReports((prevState) => prevState + 1);
                      }}
                    >
                      {" "}
                      <img
                        className={`${
                          reportLoading.fees ||
                          reportLoading.avgIncomingTransfer ||
                          reportLoading.customer ||
                          reportLoading.contract ||
                          reportLoading.customer_base
                            ? "reload"
                            : null
                        }`}
                        src={reload}
                        alt="reload"
                      />
                    </span>
                  </h3>
                </div>
                <div className="d-flex align-items-center justify-end gap-4">
                  <FilterDropdown
                    name="timeperiod"
                    type="text"
                    label="Timeperiod"
                    options={reportTimePeriods}
                    onChange={(e) => {
                      const name = e.target.value;
                      const period = reportTimePeriods.find(
                        (report) => report.name === name
                      );
                      const { fromDate, toDate } = getDateRange(period?.key);

                      setReportTimePeriod((prevState) => ({
                        ...prevState,
                        period,
                        from: fromDate,
                        to: toDate,
                      }));
                    }}
                    value={reportTimePeriod?.period?.name}
                    size={"small"}
                    render={(period, i) => (
                      <MenuItem key={period.name} value={period.name}>
                        {period.name}
                      </MenuItem>
                    )}
                  />
                  <Stack sx={{ flexDirection: "row", gap: 1 }}>
                    <Stack sx={{ width: "165px", flex: 1 }}>
                      <StyledDatePicker
                        value={reportTimePeriod.from}
                        disableFuture
                        format={"dd/MM/yyyy"}
                        onChange={(newValue) => {
                          setReportTimePeriod((prevState) => ({
                            ...prevState,
                            from: new Date(format(newValue, "yyyy/MM/dd")),
                          }));
                        }}
                      />
                    </Stack>

                    <Stack sx={{ width: "165px", flex: 1 }}>
                      <StyledDatePicker
                        value={reportTimePeriod.to}
                        disableFuture
                        // minDate={reportTimePeriod.from ?? undefined}
                        format={"dd/MM/yyyy"}
                        onChange={(newValue) => {
                          setReportTimePeriod((prevState) => ({
                            ...prevState,
                            to: new Date(format(newValue, "yyyy/MM/dd")),
                          }));
                        }}
                      />
                    </Stack>
                  </Stack>
                </div>
              </div>
              <div className="reports-container mt-4">
                <div className="report">
                  <p className="report-title">Contracts</p>
                  <Stack
                    sx={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <Stack sx={{ flexDirection: "column" }}>
                      <Typography variant={"caption"}>Amount</Typography>
                      <p className="report-data">
                        {contractReportData?.total?.amount ?? "--"}
                      </p>
                    </Stack>

                    <Stack sx={{ flexDirection: "column" }}>
                      <Typography variant={"caption"}>
                        Number of Contract
                      </Typography>
                      <p className="report-data">
                        {contractReportData?.total?.contract ?? "--"}
                      </p>
                    </Stack>
                    {/* <Stack sx={{ flexDirection: "column" }}>
                      <Typography variant={"caption"}>Total</Typography>
                      <p className="report-data">
                        {customerBaseReport?.total_count ?? "--"}
                      </p>
                    </Stack> */}
                  </Stack>

                  {reportLoading.contract ? (
                    <BarChartLoader />
                  ) : (
                    <AreaChartComp
                      data={contractReportData.data}
                      lines={["Amount", "Number_of_Contract"]}
                    />
                  )}
                </div>
                <div className="report">
                  <p className="report-title">
                    Average Incoming Transfer Value
                  </p>
                  <p className="report-data"></p>
                  {/* <p className="report-data">Outward: EUR 2,343</p> */}

                  {reportLoading.avgIncomingTransfer ? (
                    <BarChartLoader />
                  ) : (
                    <ResponsiveContainer height="100%" width="100%">
                      <BarChart
                        width={400}
                        height={300}
                        data={avgIncomingTransferData.data}
                        margin={{
                          left: 15,
                          right: 15,
                          top: 15,
                          bottom: 5,
                        }}
                        barCategoryGap={20}
                      >
                        <Tooltip />
                        <XAxis
                          scale="point"
                          dataKey="name"
                          interval="preserveStartEnd"
                        />

                        <Bar
                          barSize={10}
                          dataKey="Average"
                          fill={doughnutChartColors[0]}
                          radius={[10, 10, 0, 0]}
                        />
                      </BarChart>
                    </ResponsiveContainer>
                  )}
                </div>
                <div className="report">
                  <p className="report-title">Fees</p>
                  <div className="fee-sec-hero-stat-type">
                    <div
                      className={`stat ${showFeeIncoming ? "" : "opacity-5"}`}
                      onClick={() => toggleFeesChart("incoming")}
                    >
                      <span className="stat-selector graph-main-color"></span>
                      <span>Incoming Fee</span>
                    </div>
                    <div
                      className={`stat ${showFeeOutgoing ? "" : "opacity-5"}`}
                      onClick={() => toggleFeesChart("outgoing")}
                    >
                      <span className="stat-selector graph-main-color-sec"></span>
                      <span>Outgoing Fee</span>
                    </div>
                    <div
                      className={`stat ${showFeeInternal ? "" : "opacity-5"}`}
                      onClick={() => toggleFeesChart("internal")}
                    >
                      <span className="stat-selector graph-main-color-ter"></span>
                      <span>Internal Fee</span>
                    </div>
                    <div
                      className={`stat ${showFeeMonthly ? "" : "opacity-5"}`}
                      onClick={() => toggleFeesChart("monthly")}
                    >
                      <span className="stat-selector graph-main-color-light"></span>
                      <span>Monthly Fee</span>
                    </div>
                    <div
                      className="stat"
                      onClick={() => toggleFeesChart("reset")}
                    >
                      <span className="stat-selector"></span>
                      <span>Reset</span>
                    </div>
                  </div>
                  <div className="fee-hero-chart">
                    {fetchChartDataLoading ? (
                      <HeroBarChartLoader />
                    ) : (
                      <ResponsiveContainer height="100%" width="100%">
                        <BarChart
                          width={400}
                          height={300}
                          data={feesReportData.feesData}
                          margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 50,
                          }}
                          barCategoryGap="10%"
                        >
                          <Tooltip />
                          <XAxis
                            scale="point"
                            dataKey="name"
                            interval="preserveStartEnd"
                            tick={{ fontSize: 12 }} // Reduce font size for better fit
                            // angle={-45} // Rotate labels to avoid overlap
                            textAnchor="end" // Anchor rotated labels to the end
                          />
                          {showFeeIncoming && (
                            <Bar
                              barSize={10}
                              dataKey="Incoming"
                              fill="#1a1f64"
                              radius={[10, 10, 0, 0]}
                              stackId="a"
                            />
                          )}
                          {showFeeOutgoing && (
                            <Bar
                              barSize={10}
                              dataKey="Outgoing"
                              fill="#34c759"
                              radius={[10, 10, 0, 0]}
                              stackId="a"
                            />
                          )}
                          {showFeeInternal && (
                            <Bar
                              barSize={10}
                              dataKey="Internal"
                              fill="#ff9500"
                              radius={[10, 10, 0, 0]}
                              stackId="a"
                            />
                          )}
                          {showFeeMonthly && (
                            <Bar
                              barSize={10}
                              dataKey="Monthly"
                              fill="#5ac8fa"
                              radius={[10, 10, 0, 0]}
                              stackId="a"
                            />
                          )}
                        </BarChart>
                      </ResponsiveContainer>
                    )}
                  </div>
                </div>
                <div className="report">
                  <p className="report-title">Customer Base</p>
                  <Stack
                    sx={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <Stack sx={{ flexDirection: "column" }}>
                      <Typography variant={"caption"}>Customers</Typography>
                      <p className="report-data">
                        {customerBaseReport?.total?.customer ?? "--"}
                      </p>
                    </Stack>

                    <Stack sx={{ flexDirection: "column" }}>
                      <Typography variant={"caption"}>Beneficiary</Typography>
                      <p className="report-data">
                        {customerBaseReport?.total?.beneficiary ?? "--"}
                      </p>
                    </Stack>
                    <Stack sx={{ flexDirection: "column" }}>
                      <Typography variant={"caption"}>Total</Typography>
                      <p className="report-data">
                        {customerBaseReport?.total?.total ?? "--"}
                      </p>
                    </Stack>
                  </Stack>

                  {reportLoading.customer_base ? (
                    <BarChartLoader />
                  ) : (
                    <AreaChartComp
                      data={customerBaseReport.customerBaseData}
                      lines={["Total", "Customer", "Beneficiary"]}
                    />
                  )}
                </div>
              </div>
            </section>
          </>
        </div>
      </div>
    </>
  );
};
